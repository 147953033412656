.courseCardMain {
  // position: relative;
  // display: block;
  width: 100%;
  min-height: 100px;
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 10px;
  background-color: var(--white);
  border: none;
  box-shadow: var(--cardShadow);
  display: flex;
  // align-items: center;
  // background-color: rebeccapurple;
  padding-left: 1.5rem;
  .courseContentLeft {
    // padding: 10px;
    font-size: 30px;
    font-weight: 600;
    font-family: Figtree, serif;
    .courseCardHeader {
      display: inline-block;
      letter-spacing: 0.5px;
    }
    .courseCardSub {
      margin-top: 1rem;
      font-size: 16px;
      word-wrap: break-word;
      word-break: break-word;
      -webkit-line-clamp: 7;
      -webkit-box-orient: vertical;
      max-height: 160px;
      font-weight: normal;
      overflow: hidden;
    }
    .cardTagsMain {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      // margin-top: 1rem;
      .cardTags {
        display: inline-block;
        // background-color: red;
        font-weight: bold;
      }
    }
  }
  .courseContentRight {
    // min-height: 100px;
    // height: 100%;
    // background-color: red;
    display: flex;
    .draftImage {
      object-fit: cover;
      width: 300px;
      height: 350px;
      border-radius: 0 20px 20px 0;
    }
  }
}
.courseCardMain:hover {
  // cursor: pointer;
  .courseContentLeft {
    // cursor: pointer;
    color: var(--btnBlue);
  }
}
.managedIconContainer {
  margin-right: 1rem;
}
.courseIcons > svg {
  height: 1.3em;
  width: 1.3em;
}
.courseIcons:hover {
  border-radius: 15%;
  background-color: rgb(179, 172, 172);
}

@media (max-width: "720px") {
  .courseCardMain {
    min-height: 300px;
    // .courseContentRight{
    //     display: none;
    // }
  }
}
