.featuredContainer {
  background-color: var(--primary-white);
  .tabPanel > div {
    padding: 5px 0px 0px 20px;
  }
  .tabPanel {
    width: 100%;
    .featuredCourseCard {
      margin: 0rem 0rem 1.5rem 0rem;
      border-radius: 15px;
      // min-height: 300px;
      background-color: #eff1f6;
      cursor: pointer;
      .courseTitle {
        font-size: 1.2rem;
      }
    }
  }
  .course-image {
    height: auto;
    object-fit: cover;
    aspect-ratio: 1 / 1;
    width: 100%;
    border-radius: 15px 0px 0px 15px;
  }
  .featuredTabs {
    position: sticky;
    top: 80px;
    height: 300px;
    border-right: 1;
    color: black;
    flex-shrink: 0;
    .MuiTabs-scroller {
      .MuiTabs-flexContainer {
        align-items: start;
      }
    }
  }
  .tabPanel .course-keywords {
    text-align: left;
    margin: 5px 5px 0px 5px;
    background-color: #fff;
  }
  .img-grid-container {
    display: flex;
    justify-content: center;
    text-align: center;
  }
  @media (max-width: "900px") {
    .featuredTabs {
      background-color: #fff;
      top: 58px;
      height: 0;
    }
  }
}
@media (max-width: "900px") {
  .featuredContainer :nth-child(2) > div {
    padding-left: 0;
  }
}
@media (max-width: "480px") {
  .course-keywords {
    width: fit-content;
    max-width: 15rem;
  }
  .featuredContainer {
    .course-image {
      border-radius: 15px 15px 0px 0px;
    }
  }
}
