.homeCardMain {
  max-width: 1400px;
  position: relative;
  display: inline-block;
  vertical-align: top;
  min-height: 400px;
  background-color: #f6eeeb;
  padding: 1.8rem;
  border-radius: 20px 20px 0 20px;
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  background-image: linear-gradient(#bdc5c0, #9eb5ab);
  .homeCardContent {
    max-width: 650px;
    width: 400px;
    margin-top: 1rem;
    margin-left: 1rem;
    .homeCardHeader {
      margin-bottom: 10px;
      color: var(--primary-black);
      font-family: Figtree, serif;
      font-size: 24px;
      letter-spacing: 0.5px;
    }
    .homeCardContentText {
      color: var(--primary-black);
      font-size: 18px;
      line-height: 1.7rem;
      white-space: pre-wrap;
    }
  }
  .homeCardImage {
    right: 0px;
    bottom: 0px;
    position: absolute;
    width: 400px;
    height: 400px;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media (max-width: 720px) {
  .homeCardMain {
    position: relative;
    display: block;
    float: none;
    height: auto;
    margin-right: 0px;
    padding: 1rem;
    .homeCardContent {
      .homeCardContentText {
        width: 90%;
      }
    }
  }
}
// @media (min-width: 320px) and (max-width: 421px) {
//   .homeCardImage {
//     max-width: -webkit-fill-available;
//   }
// }

@media screen and (max-width: 600px) {
  .homeCardMain {
    display: flex;
    flex-direction: column;
    align-items: center;
    .homeCardContent {
      width: auto;
    }
    .supportCardContent {
      width: 100%;
    }
    .homeCardImage {
      position: relative;
      top: auto;
      right: auto;
      bottom: 20px;
      max-width: 300px;
      max-height: 300px;
      margin: 0 auto;
    }
  }
}
@media screen and (min-width: 601px) and (max-width: 900px) {
  .homeCardMain {
    .homeCardContent {
      width: 300px;
    }
    .homeCardImage {
      position: absolute;
      bottom: 0;
      right: 0;
      bottom: 20px;
      max-width: 300px;
      max-height: 300px;
      margin: 0 auto;
    }
  }
}
// @media screen and (max-width: 1200) {

// }

// xs, extra-small: 0px
// sm, small: 600px
// md, medium: 900px
// lg, large: 1200px
// xl, extra-large: 1536px
