.title-container {
  h2 {
    line-height: 110%;
    font-size: 2rem;
    //margin-top: 5rem;
  }
}

.courseHeaderText {
  font-weight: bolder;
  font-size: 14px;
  //   margin: 15px 0px 5px;
}
.courseCheckboxText {
  color: black;
}
.newCourseRadioBtn {
  align-items: center;
  flex-direction: row;
}
.saveNewCourseBtn {
  width: 100%;
  background-color: var(--btnBlue);
  color: var(--white);
  text-align: center;
  margin-top: 1rem;
}
.courseModalRight {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  .addCourseImage {
    border-style: dotted;
    border-radius: 4px;
    min-height: 40vh;
    min-width: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    text-align: center;
    .uploadCourseImage {
      cursor: pointer;
      opacity: 0;
      border: none;
      border-radius: 3px;
      position: absolute;
      left: 0px;
      width: 100%;
      top: 0px;
      height: 100%;
    }
    .courseImagePreview {
      max-height: 40vh;
      max-width: 40vh;
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
}

.grey {
  font-weight: 600;
  color: var(--grey);
}
.dark {
  font-weight: 600;
  color: var(--primary-black);
}
.greyBg {
  background-color: var(--primary-white);
}
.darkBg {
  background-color: var(--primary-black);
}
.blueColor {
  color: var(--postlogin-primary-color);
}
.yellowColor {
  color: var(--primary-yellow);
}
.course-main-block .css-zow5z4-MuiGrid-root > .MuiGrid-item {
  width: 100%;
}
.markAsDone {
  color: var(--white);
  background-color: var(--postlogin-primary-color);
  height: 7vh;
  width: 100%;
  margin-top: 2rem;
  font-weight: 600;
}
.enrollnow-button {
  background-color: var(--postlogin-primary-color);
  color: var(--white);
  width: 100%;
}
.confirm_name_button {
  background-color: var(--postlogin-primary-color);
  color: var(--white);
  width: 99%;
}
.blockPropertiesWrapper {
  display: flex;
  gap: 1rem;
}
@media (min-width: "300px") and (max-width: "480px") {
  .breadcrumb {
    width: inherit;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
  }
  .tab-title {
    display: none;
  }
}

@media (max-width: 480px) {
  .addCourseImage {
    min-height: 20vh;
  }
  .Coursedivider {
    margin-top: 7rem;
  }
}

.confirm_button {
  width: 100%;
  background-color: var(--btnBlue);
  color: var(--white);
  text-align: center;
  margin-top: 1rem;
}
.cancel_button {
  width: 100%;
  background-color: #f1f5f9;
  color: var(--primary-black);
  text-align: center;
  margin-top: 1rem;
}

.module_attempted {
  width: 100%;
  padding: 20px 0;
  cursor: not-allowed;
  background-color: #c4b5fd;
  color: var(--primary-black);
  text-align: center;
  margin-top: 1rem;
}
.step_icon {
  color: #1d66af;
}
.MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel {
  .MuiStepConnector-line.MuiStepConnector-lineHorizontal {
    color: #1b07f266;
    border-width: 2px;
    border-color: #1b07f266;
  }
}
.MuiStepper-root.MuiStepper-horizontal.MuiStepper-alternativeLabel.checks_failed {
  .MuiStepConnector-line.MuiStepConnector-lineHorizontal {
    color: #ef4444;
    border-color: #ef4444;
    border-width: 2px;
  }
  .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root {
    color: #ef4444;
  }
  .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root.Mui-completed {
    color: #16a34a;
  }
}
.MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel.Mui-completed {
  .MuiStepConnector-line.MuiStepConnector-lineHorizontal {
    border-color: #16a34a;
  }
}
.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root {
  color: #1b07f266;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root.Mui-completed {
  color: #16a34a;
}

//tab style
.custom-tabs {
  overflow: hidden;
  width: 100%;
  .tab-buttons {
    display: flex;
    justify-content: space-between;
    // margin-bottom: 1px;
    width: 100%;
    // padding: 5px;
    // border-radius: 2px;
    background-color: white;
    color: grey;
    border: 1px solid #365082;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px -1px;
    button {
      border: none;
      background-color: transparent;
      padding: 7px 20.5px;
      // margin: 0 5px;
      // border-radius: 3px;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 5px;
      font-weight: 600;
      color: rgb(30, 32, 34);
      font-size: 16px;
      transition: all 0.3s ease-in-out;
      &:disabled {
        color: rgba(30, 32, 34, 0.608);
      }
    }
    button.active {
      background-color: #365082;
      color: white;
      box-shadow:
        // rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
        rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    }
  }
}

@media screen and (min-width: 600px) {
  .custom-tabs {
    .tab-buttons {
      width: fit-content;
    }
  }
}

.price_input {
  border: 1px solid black;
}
