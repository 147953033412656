.grid-block {
  padding: 20px;
  position: relative;
  .block-title {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 10px;
  }
  .block-options {
    position: relative;
    margin-top: 20px;
    overflow: auto;
    > div {
      width: 433px;
      position: relative;
      display: inline-block;
      vertical-align: top;
      cursor: pointer;
      margin: 0px 20px 20px 0px;
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 10px;
    }
  }
  .block-option .block-image {
    width: 433px;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    position: relative;
    vertical-align: top;
    border-radius: 10px;
    background-color: 1A1A1A;
  }
  .block-text {
    padding: 7px;
  }
}
