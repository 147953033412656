.drawer_paper {
  min-width: 90%;
}

// .navigate-container {
//   // background-color: var(--postlogin-primary-color);
//   // border-radius: 50%;
//   // position: fixed;
//   // bottom: 16px;
//   // left: 40px;
//   // z-index: 20;
//   // padding: 8px 8px 2px 8px;
//   // box-shadow: 1px 1px 7px #000000cc;
//   background-color: var(--postlogin-primary-color);
//   border-radius: 50%;
//   position: fixed;
//   bottom: 1rem;
//   left: 1rem;
//   z-index: 20;
//   padding: 8px;
//   padding-left: 10px;
//   width: 45px;
//   height: 45px;
//   /* box-shadow: 1px 1px 7px #000000cc; */
//   cursor: pointer;
//   line-height: 2.6rem;
//   cursor: pointer;
//   .menu-icon{
//     color: #fff;
//     font-size: 24px;
//   }
// }
.navigate-container_2 {
  background-color: var(--postlogin-primary-color);
  border-radius: 50%;
  position: fixed;
  bottom: 16px;
  left: 85%;
  z-index: 20;
  padding: 8px 8px 2px 8px;
  box-shadow: 1px 1px 7px #000000cc;
  cursor: pointer;
  .close-icon {
    color: #fff;
    font-size: 24px;
  }
}

@media screen and (min-width: 600px) {
  .drawer_paper {
    min-width: auto;
  }
  .navigate-container_2 {
    background-color: var(--postlogin-primary-color);
    border-radius: 50%;
    position: fixed;
    bottom: 16px;
    left: 55%;
    z-index: 20;
    padding: 8px 8px 2px 8px;
    box-shadow: 1px 1px 7px #000000cc;
    cursor: pointer;
  }
}

@media screen and (min-width: 900px) {
  .drawer_paper {
    min-width: auto;
  }
  .navigate-container_2 {
    background-color: var(--postlogin-primary-color);
    border-radius: 50%;
    position: fixed;
    bottom: 16px;
    left: 35%;
    z-index: 20;
    padding: 8px 8px 2px 8px;
    box-shadow: 1px 1px 7px #000000cc;
    cursor: pointer;
  }
}

@media screen and (min-width: 1200px) {
  .drawer_paper {
    min-width: auto;
  }
}
