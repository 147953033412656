.response-block {
  padding: 1rem;
  overflow: hidden;
  border-radius: 10px;
  .block-title {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 10px;
    .required {
      font-weight: bold;
      font-size: 20px;
      color: red;
      padding: 0.5rem;
    }
  }
  .block-text {
    margin: 10px 0px 20px;
  }
  .text-description {
    font-size: 20px;
  }
  .text-response {
    background-color: transparent;
    border: 0px;
    height: 200px;
    padding: 10px;
  }
  .entity-text {
    position: relative;
    width: 93%;
    font-size: 20px;
    height: 100px;
    box-shadow: none;
    padding: 14px;
    border: 2px solid transparent;
    border-radius: 4px;
    outline: none;
    -webkit-appearance: none;
    resize: none;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
