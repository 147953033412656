/* styles.css */
.quill-editor {
  height: 250px; /* default height for larger screens */
}

.ql-snow .ql-picker.ql-size {
  width: 80px;
}

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 2px;
}

.ql-snow .ql-picker.ql-font {
  width: 143px;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  display: block;
  margin-top: 3px;
  top: 100%;
  z-index: 1;
  overflow: auto;
  height: 250px;
}

@media (max-width: 600px) {
  .quill-editor {
    height: 200px
  }
}
