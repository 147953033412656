.comment {
  margin: 0px 20px;
  margin-bottom: 5px;
  box-shadow: 0 4px 1px -3px rgba(0, 0, 0, 0.2);
  .comment-text {
    line-height: 20px;
    font-size: 1rem;
    padding-left: 5px;
  }
  .comment-info {
    display: flex;
    align-items: center;
    gap: 10px;
    .profile-icon {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      font-size: 12px;
      background-color: lightgrey;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .comment-meta {
      display: flex;
      align-items: center;
      gap: 10px;
      .comment-user {
        line-height: 10px;
        // font-size: 12px;
        font-weight: bold;
      }
      .comment-date {
        line-height: 10px;
        font-size: 12px;
      }
    }
    .reply-tag {
      line-height: 10px;
      font-size: 12px;
      font-style: italic;
    }
  }
  .comment-actions {
    display: flex;
    align-items: center;
    gap: 10px;
    button {
      border: none;
      background: none;
      cursor: pointer;
      font-weight: bold;
      // font-size: 10px;
    }
    .view-more {
      line-height: 10px;
      font-size: 12px;
      cursor: pointer;
    }
    .vertical-menu {
      font-size: 20px;
      cursor: pointer;
    }
    .edited {
      font-size: 10px;
      color: grey;
      font-style: italic;
    }
  }
  .reply-input {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
  }
}

.replies {
  margin-left: 50px; /* Adjust the indentation for replies */
  border-left: 1px solid #ccc; /* Add a border to indicate nesting */
  padding-left: 10px;
}
.no-more-replies {
  font-size: 12px;
  background: none;
}
.highlightedComment {
  background-color: rgba(25, 118, 210, 0.08);
  padding: 5px;
  border-radius: 5px;
}
