.videoBlock {
  .fileUpload {
    padding: 100px 40px;
  }

  .video-preview {
    margin-top: 20px;

    h3 {
      margin-bottom: 10px;
    }

    video {
      max-width: 100%;
      height: auto;
    }
  }
}
