/* Style the tab */
.tabBlock {
  .tab {
    float: left;
    border: 1px solid #ccc;
    width: 25%;
    position: absolute;
    top: 64px;
    left: 0;
    height: 100%;
    overflow: scroll;
    overflow-x: hidden;
    background-image: linear-gradient(#f5f7fa, #c3cfe2);
    .tabheader {
      font-weight: 600;
      margin: 5px 0px;
      .tab-content > div {
        margin: 0px 0 !important;
      }
    }
    .selected {
      border-left: 3px solid #1b07f2 !important;
      background-color: var(--grey);
    }
    .tablinks {
      display: flex;
      gap: 5px;
    }
    button {
      display: flex;
      align-items: center;
      padding: 10px 20px;
      width: 100%;
      border: none;
      outline: none;
      text-align: left;
      cursor: pointer;
      transition: 0.3s;
      font-size: 13px;
      line-height: 15px;
      background: transparent;
    }
  }
  /* Style the tab content */
  .tabcontent {
    float: right;
    padding: 0px 12px;
    width: 70%;
    border-left: none;
    // height: 35rem;
    // max-height: 79vh;
    overflow: auto;
    overflow-x: hidden;
    margin-top: 1rem;
    right: 0;
  }
}
.FMBlockModalMain {
  max-width: 900px !important;
  height: 90vh;
  background-image: linear-gradient(#f7f7f7, #fff);
  overflow-y: auto;
}
.buttonGreen {
  background-color: var(--postlogin-primary-color);
  color: var(--white);
  width: 30%;
  float: right;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.buttonBlack {
  background-color: var(--primary-black);
  color: var(--white);
  width: 30%;
  float: right;
  margin-top: 2rem;
}
