.container-block .video-block video {
  width: 100%;
  height: auto;
}
.video-tag {
  width: 100%;
  height: 25rem;
}
.video-recording-contents {
  text-align: center;
  color: white;
  margin-top: 3rem;
}
.clear-btn {
  background-color: green;
  color: var(--white);
}
.label-tag {
  font-size: 14px;
}
.active-button {
  border: none;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
  background-color: #42b72a;
  color: white;
  transition: all 300ms ease-in-out;
  transform: translateY(0);
}
.stop-button {
  border: none;
  font-size: 20px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
  background-color: #df3636;
  color: white;
  transition: all 300ms ease-in-out;
  transform: translateY(0);
  margin-left: 15px;
}
.save-button {
  margin-left: 20px;
}
.timer-contents {
  font-size: 54px;
  padding: 1rem 0rem;
}
.postTimer {
  color: var(--primary-black);
}
