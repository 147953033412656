.assessmentModal{
  background-color: rebeccapurple;
  max-width: 64vw;
  max-height: 95vh;
  .FMModalBody{
    overflow-x: hidden;
  }
}
.margin-top-2 {
  margin-top: 1px;
}
.bg-white {
  background-color: white;
}
.assement_tooltip {
  background-color: beige;
}
.flex {
  display: flex;
}
.items-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.justify-start {
  justify-content: start;
}
.justify-end {
  justify-content: flex-end;
  flex-direction: column;
}
.space-between {
  justify-content: space-between;
}
.flex-shrink-0 {
  flex-shrink: 0;
}
.max-w-100 {
  max-width: 100px;
  width: auto;
}
.fullwidth {
  width: 100%;
}
.width-50 {
  width: 80px;
}
// .helper_text {
//   color: red;
// }
.domain_auto_complete {
  min-width: 250px;
}
.percent {
  font-weight: 700;
  font-size: large;
  margin: 10px;
}
.add_assessment_domain_button {
  background-color: var(--btnBlue);
  color: var(--white);
  text-align: center;
  white-space: nowrap;
}
.MuiInputBase-root.MuiOutlinedInput-root.assessment_logic_input {
  padding-top: 10px;

  &:focus-within .MuiOutlinedInput-notchedOutline {
    border: 2px solid var(--inputGreen);
  }
  .verify_logic_button {
    background-color: var(--btnBlue);
    color: var(--white);
    text-align: center;
    white-space: nowrap;
  }
}

// Responsive Queries
@media screen and (min-width: 1536px) {
}

@media screen and (min-width: 1280px) {
}

@media screen and (min-width: 1024px) {
}

@media screen and (min-width: 768px) {
}

@media screen and (min-width: 640px) {
}
