.discussion_view {
  .chatview__container {
    .child__container {
      .message__container {
        .chat_input {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }
}
