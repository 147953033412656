.fsComms_table_head {
  font-weight: 600;
  border-bottom: 1px solid grey;
  text-align: left;
  padding-left: 10px;
  padding-right: 30px;
  cursor: pointer;
}
.fsComms_paper {
  overflow: visible;
}

.inactive_badge {
  background-color: #ffe9b9;
  margin-right: auto;
  color: var(--primary-black);
}

.active_badge {
  background-color: #d1fae5;
  margin-right: auto;
  // margin-left: auto;
  color: var(--primary-black);
}
