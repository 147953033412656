.container-title {
  color: var(--primary-yellow);
  font-weight: 600;
  font-size: 40px;
  margin-bottom: 5px;
  cursor: pointer;
}

.container-title-last {
  font-size: 40px;
}
.breadcrumb-module-container {
  h2 {
    font-size: 1.6rem;
  }
}

.fullwidth {
  width: 100%;
}
.MuiGrid-item.fullwidth.comment_block {
  padding-top: 0;
}

@media (min-width: "300px") and (max-width: "480px") {
  .breadcrumb-module-container {
    h2 {
      font-size: 1.5rem;
    }
  }
  .breadcrumb {
    width: inherit;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    .course-title-active {
      font-weight: 600;
      font-size: 16px;
    }
  }
  #lock-container {
    max-width: 90vw;
    padding: 40px 30px 60px 30px;
  }
}

#lock-container {
  position: fixed;
  bottom: -130px;
  right: 50%;
  left: 50%;
  transform: translate(-50%, 0%);
  border-radius: 20px 20px 0 0;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  // margin-right: -50px;
  // box-shadow: rgba(0, 0, 0, 0.3) 9px 0px 15px;
  // transform: translateX(-50%);
  width: 100%;
  max-width: 500px;
  // height: 200px;
  z-index: 99;
  text-align: center;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  padding: 40px 30px 30px 30px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  transition: all ease-in-out 0.3s;
  // -webkit-animation: ease-in-out 0.5s both;
  // animation: ease-in-out 0.5s both;
  &:hover {
    bottom: 0;
    box-shadow: rgba(0, 0, 0, 0.3) 9px 0px 15px;

    // -webkit-animation: ease-in-out 0.5s both;
    // animation: ease-in-out 0.5s both;
  }
}

.lock-icon-container {
  width: 120px;
  height: 60px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  right: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50px;
  z-index: 999;
  background-color: #d7e4f0;
  padding: 5px;
  color: #1976d2;
  border: 2px solid white;
  margin-top: -40px;
  .lock-icon {
    background-color: white;
    height: 50px;
    width: 50px;
    padding: 8px;
    border-radius: 25px;
  }
  .unlock-icon {
    // background-color: white;
    height: 50px;
    width: 50px;
    padding: 8px;
    color: rgb(62, 64, 67);
    border-radius: 25px;
  }
}
@media (max-width: 600px) {
  #lock-container {
    max-width: 500px;
    padding: 40px 30px 70px 30px;
    bottom: -193px;
  }
}
