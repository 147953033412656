#text-view {
  padding: 1rem;
  border-radius: 10px;
  // overflow: hidden;
  position: relative;
  > .text_view_content img {
    max-width: 100% !important;
    display: block;
    margin: 20px 0px;
  }
  .text_with_art {
    max-width: 75%;
  }
  img {
    width: 100%;
  }
  a {
    overflow-wrap: anywhere;
  }
  pre {
    background-color: #5a5a5a;
    padding: 1rem;
    overflow: auto;
  }
  p {
    line-height: 1.3;
    overflow-wrap: break-word;
  }
}
#text-view > *:last-of-type {
  margin-bottom: 0px;
}

.preBgColor {
  background-color: var(--primary-background-color);
}
.postBgColor {
  background-color: var(--white);
  border: 1px solid #e9e9ef;
  box-shadow: 0px 0px 20px #e9e9ef;
  border-radius: 10px;
}

@media screen and (max-width: 600px) {
  #text-view div > p > img {
    width: 100%;
  }
  #text-view {
    .text_with_art {
      max-width: 100%;
    }
  }
}
