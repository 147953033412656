.update_profile_button {
  background-color: var(--btnBlue);
  color: var(--white);
  text-align: center;
  margin-top: 1rem;
  padding: 15px 30px;
  margin-bottom: 3rem;
}
.change_profile_photo{
  background-color: var(--btnBlue);
  color: var(--white);
  text-align: center;
  margin-top: 1rem;
  padding: 15px 30px;
}
.dp{
  height: 300px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.Mui-focused{
  fieldset {
    border: 2px solid var(--inputGreen);
    color: var(--inputGreen);
  }
}
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-md-4.fullwidth {
  .inputFile{
    height: 100%;
  }
  .custom-file-upload.fileUpload{
    border: 2px dotted var(--primary-black);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 50px 40px;
    height: 100%;
    cursor: pointer;
    width: 100%;
    font-size: 16px;
    text-align: center;
    border-radius: 4px;
    background-color: #f0f0f0;
  }
}

.MuiGrid-root.MuiGrid-item.MuiGrid-grid-md-3.fullwidth{
   ._1Lxpd {
    width: 100%;
    height: 60px;
    ._1KMXW{
z-index: 50;
    }
    ._30Ipg {
      // background: rgb(255, 255, 255);
      border: 1px solid  rgba(0, 0, 0, 0.23);
      display: flex;
      height: 56px;
      width: 100%;
      position: relative;
      padding: 0 10px;
      align-items: center;
      cursor: pointer;
    }
   }
}

.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input>span.notranslate {
  display: none;
}
