.buttonMain {
  display: inline-block;
  padding: 10px 15px;
  font-weight: 600;
  font-size: 18px;
  border-radius: 4px;
  margin-right: 2px;
  vertical-align: top;
  cursor: pointer;
  font-weight: 600;
  border: none;
  line-height: normal;
}
.buttonDisabled {
  opacity: 0.5;
}
.primary {
  background-color: var(--primary-yellow);
  color: var(--primary-black);
}
.secondary {
  background-color: var(--white);
  color: var(--primary-black);
}
.black {
  background-color: var(--primary-black);
  color: var(--white);
}
.primary-blue {
  background-color: var(--btnBlue);
  color: var(--white);
}
.transparent {
  background-color: transparent;
  color: #fff;
  padding: 10px;
}
.tangerine {
  background-color: var(--button-tangerine);
  color: #fff;
  //padding: 10px;
}
@media (max-width: 900px) {
  .buttonMain {
    padding: 9px 15px 8px;
  }
}
@media (max-width: "369px") {
  .buttonMain {
    padding: 9px 12px 8px;
  }
}
