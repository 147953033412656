.signupMainContainer {
  .leftBtnTxt {
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 7px;
  }
  .checkBoxSignup {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    .checkboxSignupCheck {
      cursor: pointer;
    }
    .checkboxLabelSignup {
      cursor: pointer;
      .checkboxTermsSignup {
        text-decoration: underline;
        font-weight: bold;
      }
    }
  }
  .signupBtn {
    width: 100%;
    .leftBtn {
      width: 100%;
    }
  }
}
.loginLayoutMainContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .loginLayoutContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 2rem;
    .forgotPasswordMain {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      .forgotPasswordText {
        font-size: 14px;
      }
    }
  }
  .leftBtnMain {
    // width: 100%;
    // margin-top: 1rem;
    // width: 100%;
    .leftBtn {
      width: 100%;
    }
  }
}
@media (max-width: "426px") {
  .loginLayoutMainContainer {
    .loginLayoutContainer {
      margin-top: 1.3rem;
      .forgotPasswordMain {
        margin-bottom: 1rem;
      }
    }
  }
}
.rectangular-box {
  background-color: #08519a;
  padding: 5px 0px 5px 15px;
  position: absolute;
  width: 100%;
  left: 0;
  // text-align: center;
  top: 0;
  .box-message {
    font-size: 14px;
    color: #fff;
    margin: 0;
    line-height: 15px;
    display: flex;
    align-items: center;
    gap: 5px;
  }
}
@media (max-width: "600px") {
  .rectangular-box {
    margin: 0;
  }
}
