.css-1y1379l {
  margin: 0rem 1rem;
}
.grid-loader-container {
  .course-title {
    font-weight: 600;
    font-size: 1.5rem;
  }
}
.course-button-tab {
  margin-right: 3px;
  min-width: 7rem;
  min-height: 37px;
  padding: 7px;
  text-transform: none;
  font-weight: bold;
}
.course-container-tabs > div > span {
  display: none;
}
.course-loader-panel {
  #paperDark {
    padding: 0;
    margin: 2rem 0rem;
    background-color: var(--primary-background-color);
    color: var(--white);
    border-radius: 15px;
    :hover {
      cursor: pointer;
    }
  }
  #paperLight {
    padding: 0;
    margin: 2rem 0rem;
    background-color: var(--white);
    color: var(--primary-background-color);
    border-radius: 15px;
    :hover {
      cursor: pointer;
    }
  }
  .course-tagline {
    margin: 1rem 0rem 1.5rem 0rem;
  }
  .course-loader-img {
    height: auto;
    width: 80%;
  }
}
@media (min-width: "481px") and (max-width: "900px") {
  .course-loader-panel {
    #paperDark,
    #paperLight {
      margin-bottom: 3.5rem;
    }
  }
}
@media only screen and (min-width: "320px") and (max-width: "480px") {
  .course-button-tab {
    width: 32%;
    margin-left: 1px;
  }
  .css-6wihrf-MuiGrid-root {
    padding: 1.5rem;
  }
  .course-loader-img {
    border-radius: 5px;
    width: 100%;
    height: 297px;
  }
  .course-title {
    font-size: 20px;
  }
  .grid-loader-container {
    padding: 0rem 1.5rem;
  }
  .css-1u87t6f {
    margin-bottom: 1rem;
  }
  .img-loader-container {
    height: 290px;
  }
}

@media (min-width: "0px") and (max-width: "900px") {
  .course-loader-panel {
    .course-loader-img {
      width: 100%;
      border-radius: 0rem;
      height: 289px;
      border-radius: 0rem 0rem 0.5rem 0.5rem;
    }
    .grid-loader-container {
      padding: 0rem 1rem 0rem 1.9rem;
    }
    .img-loader-container {
      height: 307px;
    }
    .course-loader-panel .MuiBox-root.css-19kzrtu {
      padding: 24px 0px;
    }
    .course-loader-panel #paper {
      margin-bottom: 4rem;
    }
    .course-title {
      height: 3.8rem;
    }
    .course-tagline {
      height: 8.3rem;
    }
  }
}
@media (min-width: "900px") {
  .course-loader-panel {
    .img-loader-container {
      padding: 0;
      display: flex;
      justify-content: end;
    }
    .course-loader-img {
      border-radius: 0px 10px 10px 0px;
      min-height: 350px;
    }
    .grid-loader-container {
      padding: 0.5rem 1.5rem 0.5rem 2.5rem;
    }
    .title-skeleton {
      width: 70%;
    }
    .course-title {
      height: 3.5rem;
    }
  }
  .course-loader-panel .MuiBox-root.css-19kzrtu {
    padding: 24px 0px;
  }
}

//bounce dots loader
.bounce-loader{
.bouncing-loader {
  display: flex;
  justify-content: center;
}

.bouncing-loader > div {
  width: 16px;
  height: 16px;
  margin: 3px 6px;
  border-radius: 50%;
  background-color: #a3a1a1;
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-16px);
  }
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}
}
.loading_more_container {
  margin-left: 70px;
  .loader_more {
    width: 24px; /* Adjust the width */
    height: 24px; /* Adjust the height */
    border: 2px dotted #FFF; /* Adjust the border width and style */
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 2s linear infinite;
  }

  .loader_more::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 2px dotted #949191; /* Adjust the border width and style */
    border-radius: 50%;
    width: 12px; /* Adjust the width */
    height: 12px; /* Adjust the height */
    animation: rotationBack 1s linear infinite;
    transform-origin: center center;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes rotationBack {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
}



