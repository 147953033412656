.one-block.with-art > *:not(.block-actions) {
  padding-right: 220px;
}
.block-art {
  width: 200px;
  height: 112px;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  bottom: 0;
}
.art-image {
  height: auto;
  width: 100%;
  object-fit: contain;
}
@media screen and (max-width: 600px) {
  .block-art {
    width: 200px;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    right: 0;
    bottom: 0;
    margin-left: auto;
  }
}
@media screen and (min-width: 601px) and (max-width: 900px) {
}
