.bannerImage {
  height: 250px;
  width: 250px;
}
.support__img {
  width: 75%;
}
@media (max-width: 426px) {
  .analyticsDataContainer {
    .analyticData {
      margin: 1rem 0rem;
    }
  }
  .bannerImage {
    margin: auto;
  }
}
