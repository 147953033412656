.receipt {
  display: flex;
  align-items: center;
  justify-content: center;
}
.receipt_not_found_box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
  border: 1px solid #dc2626;
  background-color: #fecaca;
}
.receipt_payment_box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 25px;
}
.receipt_thank_you_message_box {
  padding: 25px;
  color: white;
  background-color: #059669;
}
.receipt_payment_check_box {
  background-color: #10b9813b;
  padding: 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 10px;
}
.receipt_payment_check_box_inner {
  background-color: #10b981;
  padding: 5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.receipt_heading {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}
.receipt_thank_you_message {
  font-size: 24px;
}
.download_receipt_button {
  background-color: var(--btnBlue);
  color: white;
  width: 100%;
}

.receipt_content {
  color: black;
  max-width: 400px;
  background-color: white;
}
.receipt_main_content {
  padding: 25px;
}
.receipt_order_id {
  font-size: 14px;
}
.receipt_content_titles {
  color: dimgray;
  font-size: 14px;
  // font-weight: 600;
}
@media print {
  .download_receipt_button {
    display: none;
  }

  //   body:not(.receipt_content) {
  //     display: none;
  //   }
  //   .receipt_content {
  //     color: black;
  //   }
}
