.comment__card{
    .reply-button{
        border: none;
        cursor: pointer;
        background: none;
    }
    .no-more-replies{
        margin-left: 25px;
        line-height: 10px;
    }
    .MuiCardContent-root:last-child{
        padding-bottom: 16px;
    }
}