.reviewPageHeaderText{
    font-size: 32px;
    font-weight: 600;
    color: var(--primary-black);
    margin-bottom: 1.5rem;
}
.reviewHeaders{
    font-size: 22px;
    font-weight: 600;
    color: var(--primary-black);
}
.reviewCard {
    position: relative;
    border-radius: 10px;
    height: 70px;
    padding: 0.8rem;
    margin: 1rem 0rem;
    display: flex;
    justify-content: space-between;
      align-items: center; /* Align items to the top */
    overflow: hidden; /* Hide overflow if necessary */
    .reviewCardheader{
        font-size: 18px;
        font-weight: 500;
        color: var(--primary-black);
     max-width: 70%; /* Set a max width for the title */
    }
}

.certificateStatus {
    position: absolute; 
    display: flex;
    align-items: left;
    flex-direction: column;
    left: 990px; 
}


.buttonContainer {
    position: absolute;
    right: 1rem; 
}


.reviewSkeleton{
    border-radius: 10px;
}