.joinCohortModal {
  max-height: 90vh;
  overflow: auto;
}
.join-cohort-buttons {
  background-color: var(--postlogin-primary-color);
  color: var(--white);
  width: 100%;
}

@media (min-width: 768px) {
  .joinCohortModal {
    max-width: 70%;
  }
}
.join-cohort-content {
  display: flex;
  gap: 10px;
  .cohort-card {
    width: 100%;
    .cohort-card-content {
      padding: 20px;
      height: 100%;
      .sd-button {
        margin-top: 5.7rem;
      }
    }
  }
}
@media (min-width: "320px") and (max-width: "720px") {
  .join-cohort-content {
    display: grid;
    gap: 10px;
  }
}

@media (min-width: "1024px") {
  .joinCohortModal {
    width: 50%;
  }
}

.cohort-card {
  .cohort-card-content {
    .join_cohort_input_box {
      border: 1px solid rgb(129, 129, 129);
      margin-bottom: 10px;
    }
  }
}
