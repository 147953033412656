.block-table {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  position: relative;
  .block-title {
    font-weight: 600;
    margin: 0rem 2rem;
    font-size: 20px;
  }
  .file-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    text-decoration: none;
  }
  .container-block {
    padding: 16px;
  }
}
.pdf-icon {
  font-size: 7rem;
}
.area {
  height: 300px;
  width: 300px;
  border: 4px dashed #000;
  background-image: url("http://kmtlondon.com/img/upload.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 64px 64px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  filter: alpha(opacity=50);
  -khtml-opacity: 0.5;
  -moz-opacity: 0.5;
  opacity: 0.5;
  text-align: center;
  margin: 2rem auto;
  position: relative;
}
.upload-icon {
  position: absolute;
  top: 30%;
  left: 14%;
  color: white;
}

.area:hover,
.area.dragging,
.area.uploading {
  filter: alpha(opacity=100);
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
}

.area input {
  cursor: pointer;
  display: none;
}

.area input:focus {
  outline: none;
}
.file-save-button {
  text-align: center;
  .fileview {
    padding: 80px 50px;
  }
}
@media (max-width: "375px") {
  .area {
    width: 200px;
    height: 200px;
  }
}
