.selectedItem {
  background-color: var(--postlogin-primary-color);
  color: var(--white);
}

.dividerContainer {
  //padding: 16px;

  .animationBox,
  .musicBox {
    display: flex;
    gap: 5px;
    margin: 15px 0;

    @media (max-width: 600px) {
      flex-direction: column;
      gap: 10px;

      .MuiChip-root {
        width: 100%;
        justify-content: center;
      }
    }
  }

  // .MuiBox-root {
  //   margin: 15px 0 30px 0;

  //   @media (max-width: 600px) {
  //     margin: 10px 0;
  //   }
  // }
}
