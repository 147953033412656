.embedBubbleWrapper{
    display: flex;
    gap: 1rem;
    margin-bottom: 1.5rem;
    flex-wrap: wrap;
}
.embedBubble{
    border-radius: 14px;
    padding: 0 1rem;
    font-size: 16px;
    cursor: pointer;
    border: none;
    font-weight: 500;
    box-shadow: var(--cardShadow);
}
.embedBubbleNotSelected{
    background-color: rgb(204, 203, 203);
    color: rgb(49, 49, 49);
}
.embedBubbleSelected {
    background-color: var(--btnBlue);
    color: var(--white);
}