.footerMain {
  max-width: 1400px;
  margin-top: 2rem;
  .footerMGIEP {
    display: flex;
    align-items: center;
    gap: 2rem;
    :hover {
      color: var(--primary-yellow);
      cursor: pointer;
    }
    .footer-made img {
      width: 120px;
    }
  }
  .footerJustifyRight {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 2rem;
    :hover {
      color: var(--primary-yellow);
    }
    .footerTxt {
      cursor: pointer;
    }
  }
}

@media (max-width: 900px) {
  .footerMain {
    .footerJustifyRight {
      align-items: flex-start;
      flex-direction: column;
      gap: 1rem;
    }
    .footerMGIEP {
      .footer-made img {
        margin-left: 0;
      }
    }
  }
}
@media (max-width: 720px) {
  .footerMain {
    margin-top: 1rem;
    .footerMGIEP {
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
    }
  }
}
