.chatview__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .tabs {
    margin: auto;
    color: #fff;
    width: fit-content;
    .tabsMain {
      margin-top: 1rem;
      .specific-tab {
        padding: 0.5rem;
        color: black;
        font-weight: bold;
        text-transform: none;
      }
    }
  }
  .message__container {
    font-weight: normal;
    margin-bottom: 4rem;
    .chat__input {
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 999;
      padding: 10px 40px;
      /*new*/
      background-color: white;
      position: absolute;
      background: #fff;
      margin: 0 1rem;
      .textArea {
        background: rgb(238, 237, 237);
        border-radius: 10px;
        padding: 10px;
        border: transparent;
        width: 431px;
        height: 76px;
        resize: none;
        font-size: 12px;
      }
      .textArea::placeholder {
        font-size: 12px;
      }
    }
  }
}
.comment_container {
  margin: 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  .comment_box {
    display: flex;
    gap: 1rem;
    align-items: start;
    .comment_message_container {
      display: grid;
      .message_header {
        display: flex;
        gap: 0.5rem;
        align-items: center;
      }
      .comment_body {
        line-height: 1.25rem;
      }
      .comment_footer {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        .reply {
          display: flex;
          align-items: center;
          gap: 5px;
          font-weight: bold;
          line-height: 1.25rem;
          cursor: pointer;
          padding: 7px;
          transition:
            background 0.3s,
            font-size 0.3s,
            border-radius 0.3s;
          &:hover {
            background: lightgrey;
            font-size: 0.83em;
            border-radius: 5px;
          }
        }
        .more {
          font-weight: bold;
          cursor: pointer;
          padding: 3px 5px;
          transition:
            background 0.3s,
            font-size 0.3s,
            border-radius 0.3s;
          &:hover {
            background: lightgrey;
            border-radius: 5px;
          }
        }
      }
    }
    .reply_container {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      .reply_box {
        display: flex;
        gap: 1rem;
        align-items: start;
        .reply_message_container {
          display: grid;
          .reply_header {
            display: flex;
            align-items: center;
            gap: 0.5rem;
          }
          .reply_body {
            line-height: 1.25rem;
          }
          .reply_footer {
            display: flex;
            gap: 1rem;
            align-items: center;
            .reply {
              display: flex;
              align-items: center;
              gap: 5px;
              font-weight: bold;
              line-height: 1.25rem;
              cursor: pointer;
              padding: 7px;
              transition:
                background 0.3s,
                font-size 0.3s,
                border-radius 0.3s;
              &:hover {
                background: lightgrey;
                font-size: 0.83em;
                border-radius: 5px;
              }
            }
            .more {
              font-weight: bold;
              cursor: pointer;
              padding: 3px 5px;
              transition:
                background 0.3s,
                font-size 0.3s,
                border-radius 0.3s;
              &:hover {
                background: lightgrey;
                border-radius: 5px;
              }
            }
          }
        }
      }
    }
    .replyInputBox {
      border-radius: 5px;
      padding: 0px 10px;
      border: 1px solid lightgrey;
    }
  }
}
.load-more-container {
  padding: 10px;
  text-align: center;
  .load-more-button {
    border: none;
    background: none;
    cursor: pointer;
    font-weight: bold;
    div {
      display: flex;
      align-items: center;
      gap: 3px;
    }
  }
}
@media screen and (max-width: 426px) {
  .chatview__container {
    .message__container {
      margin-bottom: 4rem;
      .chat__input {
        bottom: 0;
        left: 0;
        right: 0;
        position: fixed;
        z-index: 999;
        padding: 10px;
        /*new*/
        background-color: white;
        background: #fff;
        margin: 0 1rem;
        .textArea {
          background: rgb(238, 237, 237);
          border-radius: 10px;
          padding: 10px;
          border: transparent;
          width: 431px;
          height: 76px;
          resize: none;
          font-size: 12px;
        }
        .textArea::placeholder {
          font-size: 12px;
        }
      }
    }
  }
  .chatview__container .child__container {
    height: 100vh;
  }
}
