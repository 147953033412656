.verifyModalContainer{
    backdrop-filter:blur(2px);
}
.verifyModalMain{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 40%;
    min-height: 30vh;
        .verifyIconContainer{
            position: absolute;
            float: right;
            right: 10px;
            top: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 2rem;
            height: 2rem;
            background-repeat: no-repeat;
            background-size: 30px 30px;
            background-color: rgba(255, 255, 255, 0.3);
            border-radius: 4px;
            cursor: pointer;
        }
    .loginInputHeader{
        font-size: 30px;
        margin-bottom: 1rem;
    }
    .verifyCertificate{
        padding: 1rem;
        width: 100%;
        // background-color: red;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .verifyBtn{
        margin-top: 1rem;
        width: 100%;
    }
    .verifiedDataContainer{
        margin-top: 1rem;
        display: flex;
        gap: 1rem;
        .courseData{
            width: 100%;
        }
    }
    @media (max-width:532px){
        .verifiedDataContainer{
            flex-wrap: wrap;
        }
    }
    .verifyContentText{
        margin-top: 1rem;
        font-size: 15px;
    }
}
@media (max-width: 1200px) {
    .verifyModalMain{
    max-width: 90%;
    min-height: 25vh;
    .loginInputHeader{
        font-size: 20px;
    }
    }
}
.verifyCert-Input{
    margin-bottom: 10px;
}