.copyCourseContainer{
    // color: black;
    // height: 30vh;
}
.copyCourseForm{
    // width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5rem
}
.copyButton{
    width: 100%;
}