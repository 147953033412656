.textArea{
    width: 100%;
    resize:none;
    background-color: white;
    width: 100%;
    font-size: 18px;
    padding: 1rem;
    color: var(--primary-black);
    border: 2px solid transparent;
    outline: none;
    border-radius: 4px;
    display: flex;
    align-items: center;    
}
.textArea::placeholder{
    font-size: 16px;
    color: #838383;
}
.textArea:focus-within {
    border: 2px solid var(--inputGreen);
  }