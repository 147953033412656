.button-view {
  position: relative;
}

.main-button-one {
  width: 100%;
  /* Avoid setting text-align here */
  position: relative;
  display: block;
  padding: 20px;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.5px;
}

.full-width-btn {
  width: 100%;
  padding: 15px;
}
