.scoreBoardWrapper {
  display: flex;
  width: 100%;
  gap: 1rem;

  .bgStyle {
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
  }

  .scoreBlock {
    width: 50%;
    background: url("../../assets/purpleScoreBG.png");
    border-radius: 12px;
    min-height: 10rem;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    box-shadow: 0px 0px 10px 0px #ccc;

    .chartWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      .chartMain {
        background-color: rgb(247, 129, 83);
      }

      .scoreHeadingText {
        background-color: rgb(255, 240, 243);
        border-radius: 18px;
        padding: 2px 10px;
        color: rgb(86, 32, 94);
        font-weight: 500;
        white-space: nowrap;
        font-size: 16px;
      }
    }

    .receivedAndMaxWrapper {
      background-color: rgb(255, 240, 243);
      padding: 2rem;
      border-radius: 5px;

      .receivedAndMaxScore {
        color: rgb(86, 32, 94);
        text-align: center;
        font-weight: bold;
        font-size: 20px;
      }

      .scoreText {
        text-align: center;
        color: rgb(86, 32, 94);
        font-size: 16px;
      }
    }
  }

  .dateBlockWrapper {
    background: url("../../assets//blueScoreBG.png");
    border-radius: 10px;
    background-repeat: no-repeat;
    background-size: cover;
    width: 50%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    min-height: 200px;
    box-shadow: 0px 0px 10px 0px #ccc;

    .enrollmentDateWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;

      .scoreCardEnrollmentDateText {
        color: rgb(233, 237, 255);

        border-radius: 6px;
        background-color: transparent;
        padding: 0.7rem 1rem;
        font-weight: bold;
        font-size: 16px;
      }

      .numberOfDaysText {
        background-color: rgb(233, 237, 255);
        color: rgb(59, 59, 122);
        border-radius: 6px;
        padding: 0.7rem 1rem;
        font-weight: bold;
        font-size: 16px;
      }
    }

    .enrollMentDayText {
      background-color: rgb(233, 237, 255);
      border-radius: 18px;
      padding: 2px 8px;
      color: rgb(59, 59, 122);
      font-weight: 500;
      font-size: 16px;
      min-width: 8vw;
      text-align: center;
    }
  }
}

.enrollmentInfoThinWrapper {
  background: url("../../assets//blueScoreBG.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 2px;
  padding: 1rem;
  // box-shadow: 0px 0px 10px 0px #ccc
}

.verticalLine {
  border-left: 1px solid #e5e5e5;
  max-height: 150px;
  // min-height: 120px;
  left: 50%;
  top: 0;
}

.dateBlockWrapper {
  background: url("../../assets//blueScoreBG.png");
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 49.5%;
  display: flex;
  justify-content: space-evenly;

  align-items: center;
  min-height: 200px;

  .enrollmentDateWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    .scoreCardEnrollmentDateText {
      color: rgb(233, 237, 255);
    }

    .numberOfDaysText {
      color: rgb(59, 59, 122);
      background-color: rgb(233, 237, 255);
      border-radius: 6px;
      padding: 0.7rem 1rem;
      font-weight: bold;
      font-size: 16px;
    }
  }

  .enrollMentDayText {
    background-color: rgb(233, 237, 255);
    border: 2px solid #4065ac;
    border-radius: 18px;
    padding: 2px 10px;
    color: rgb(86, 32, 94);
    font-weight: 500;
    font-size: 16px;
  }
}
.enrollmentInfoThinWrapper {
  display: flex;
  justify-content: space-evenly;
  .enrollmentInfoThinDateWrapper {
    display: flex;
    align-items: center;
    .enrollThinHeading {
      color: white;
      font-size: 18px;
    }
    .enrollmentInfoThinDate {
      background-color: #ecf6ff;
      border: 2px solid #4065ac;
      border-radius: 3px;
      padding: 4px 8px;
      color: rgb(59, 59, 122);
      font-weight: 600;
      font-size: 18px;
      text-align: center;
    }
  }
}

.moduleScoreMain {
  display: flex;
  justify-content: center;
}

// .css-1v603wu-MuiResponsiveChart-container {
//     width: 150px;
//     height: 180px;
// }

// .css-1821uf4 {
//     width: 0;
//     height: 0;
//     padding: 10px;
// }

.moduleScoreCellWrapper {
  // background-color: rgb(190, 135, 222);
  padding: 1px;
  border: 1px solid #d5dffd;
  max-width: 160px;
  border-radius: 22px;

  .moduletableCellWrapper {
    .moduleScoreWrapper {
      display: flex;
      border-radius: 22px;
      align-items: center;
      justify-content: center;
      padding: 5px 8px;

      min-width: 8vw;

      .moduleScoreText {
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}

.advanceMain {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .advanceScoreCardWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 1.5rem;
  }
}

.advanceCard {
  background-color: #e8f4fa;
  border-radius: 8px;
  padding: 1rem 0 0 0;
  box-shadow: 0px 0px 10px 0px #ccc;
  min-width: 23%;

  .advanceTitleText {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: #435e7d;
  }

  .advanceScoreWrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .advanceScoreContainer {
      padding: 0 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;

      .advanceScoreHeading {
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        color: #435e7d;
      }

      .advanceScoreText {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        color: #435e7d;
      }
    }
  }
}

.skeletonWrapper {
  display: flex;
  gap: 1rem;

  .ScoreSkeleton {
    width: 50%;
    height: 200px;
  }
}

@media (max-width: 1300px) {
  .scoreBoardWrapper {
    flex-direction: column;

    .scoreBlock {
      width: 100%;
      height: 160px;
      padding: 0;
      justify-content: center;
      gap: 1rem;

      .chartWrapper {
        gap: 0rem;
      }

      .receivedAndMaxWrapper {
        padding: 1rem;
      }

      // .css-1v603wu-MuiResponsiveChart-container{
      //     padding: 1.5rem;
      // }
      .css-1v603wu-MuiResponsiveChart-container {
        width: 140px;
      }
    }

    .dateBlockWrapper {
      width: 100%;
      min-height: 160px;
      padding: 0;
    }
  }

  .dateBlockWrapper {
    width: 100%;
    max-width: 100%;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 180px;

    .enrollmentDateWrapper {
      width: 50%;

      .enrollMentDayText {
        font-size: 14px;
        text-align: center;
      }

      .numberOfDaysText {
        padding: 0;
        color: white;
        background-color: transparent;
        border-radius: 6px;
        font-weight: bold;
        font-size: 16px;
      }
    }
  }

  .advanceMain {
    .advanceScoreCardWrapper {
      justify-content: center;
    }
  }
}

@media (max-width: 600px) {
  .scoreHeadingText {
    display: none;
  }
  .enrollmentInfoThinWrapper {
    .enrollmentInfoThinDateWrapper {
      flex-direction: column;
      gap: 0.5rem;
    }
  }
}

@media (max-width: 900px) {
  .skeletonWrapper {
    flex-direction: column;

    .ScoreSkeleton {
      width: 100%;
    }
  }
}
