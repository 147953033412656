.box .paragraph-text {
  max-width: 600px;
  margin-top: 0.5rem;
}
.read-more {
  color: #896e01;
  white-space: nowrap;
}
.box {
  .newCourseBtnMain {
    display: flex;
    justify-content: space-between;
    .newCourseBtn {
      display: flex;
      align-items: center;
      padding: 10px 15px 10px 15px;
      .courseBtnIcon {
        width: 1.2rem;
        height: 1.2rem;
      }
    }
  }
}
.css-1y1379l {
  margin: 0rem 1rem;
}
.grid-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-right: 0;
  .course-title {
    font-weight: 600;
    font-size: 1.5rem;
    margin-top: 1rem;
  }
  .course-tagline {
    margin-top: 0.5rem;
  }
  .tag {
    margin-top: auto;
  }
}
.course-button-tab {
  margin-right: 3px;
  min-width: 7rem;
  min-height: 37px;
  padding: 7px;
  text-transform: none;
  font-weight: bold;
}
.pre-course-button-tab.Mui-selected {
  color: var(--white);
  border-bottom: 2px solid var(--primary-yellow);
}
.post-course-button-tab.Mui-selected {
  color: var(--primary-background-color);
  border-bottom: 2px solid var(--primary-yellow);
}
.pre-course-button-tab-white {
  color: var(--white);
}
.post-course-button-tab-blue {
  color: var(--primary-background-color);
}
.grid-text-container .tags {
  margin-top: 10%;
}
.course-container-tabs > div > span {
  display: none;
}
.tabPanel #paperDark {
  padding: 0;
  margin-bottom: 2rem;
  background-color: var(--primary-background-color);
  color: var(--white);
  border-radius: 15px;
  :hover {
    cursor: pointer;
  }
}
.tabPanel #paperLight {
  padding: 0;
  margin-bottom: 2rem;
  background-color: var(--white);
  color: var(--primary-background-color);
  border-radius: 15px;
  :hover {
    cursor: pointer;
  }
}
.tabPanel > div {
  padding: 1rem 0rem;
}
.course-img {
  object-fit: cover;
  height: auto;
  width: 70%;
}
.img-container {
  padding: 0.5rem;
  display: flex;
  justify-content: end;
}
@media (min-width: "481px") and (max-width: "900px") {
  .course-tab-panel {
    #paperDark,
    #paperLight {
      margin-bottom: 3.5rem;
    }
  }
}
@media only screen and (min-width: "320px") and (max-width: "480px") {
  .course-button-tab {
    width: 32%;
    margin-left: 1px;
  }
  .css-6wihrf-MuiGrid-root {
    padding: 1.5rem;
  }
  .course-img {
    border-radius: 5px;
    width: 100%;
  }
  .course-title {
    font-size: 20px;
  }
  .grid-text-container {
    padding: 0rem 1.5rem;
  }
  .css-1u87t6f {
    margin-bottom: 1rem;
  }
  .grid-text-container .tag {
    margin-top: 1rem;
  }
}

@media (min-width: "0px") and (max-width: "900px") {
  .box {
    .newCourseBtnMain {
      display: block;
      .newCourseBtn {
        margin-top: 1rem;
      }
    }
  }
  .course_card .img-container .course-img {
    width: 100%;
    border-radius: 0rem;
    border-radius: 0rem 0rem 0.5rem 0.5rem;
  }
  .grid-text-container {
    padding: 0rem 2rem;
  }
  .img-container {
    // padding-bottom: 0;
    display: flex;
    justify-content: end;
  }
  .course-tab-panel .MuiBox-root.css-19kzrtu {
    padding: 24px 0px;
  }
  .course-tab-panel #paper {
    margin-bottom: 4rem;
  }
}
@media (min-width: "900px") {
  .img-container {
    padding: 0.5rem;
    display: flex;
    justify-content: end;
  }
  .course-img {
    border-radius: 0px 10px 10px 0px;
    min-height: 300px;
  }
  .course-tab-panel .MuiBox-root.css-19kzrtu {
    padding: 24px 0px;
  }
}
