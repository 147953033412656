.courseModalContainer {
  backdrop-filter: blur(2px);
}
.showUniqueID_Main {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 40%;
  min-height: 40vh;
  background-color: var(--white);
  border-radius: 4px;
  padding: 1rem;
  border: none;
  .uniquiID-headerContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // background-color: rebeccapurple;
    height: 3rem;
    .uniqueID_header {
      font-size: 24px;
    }
    .uniqueIDiconClose {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2rem;
      height: 2rem;
      background-repeat: no-repeat;
      background-size: 30px 30px;
      background-color: rgba(170, 170, 170, 0.3);
      border-radius: 4px;
      cursor: pointer;
    }
  }
  .uniqueID_textMain {
    font-size: 16px;
    .uniqueID_text {
      color: var(--btnBlue);
      font-weight: bold;
    }
  }
  .copyContainer {
    background-color: var(--primary-white);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    padding: 0 1rem;
    max-height: 3rem;
    .copyRound {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: var(--btnBlue);
      width: 38px;
      height: 38px;
      border: none;
      cursor: pointer;
    }
  }
}
.createCourseMainForm {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  .courseFlex {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}
.uploadSignature {
  cursor: pointer;
  opacity: 0;
  border: none;
  border-radius: 3px;
  position: absolute;
  left: 0px;
  width: 100%;
  top: 0px;
  height: 100%;
}
.signatureContainer {
  border-style: dotted;
  border-radius: 4px;
  min-height: 30vh;
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
}
.flexImgContainer {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.certificateConfigContainer {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
}
.certificateConfigBtn {
  width: 100%;
  margin-top: 1rem;
}
.regenerate-button-container {
  display: flex;
  margin-top: 10px;
  justify-content: start;
  flex-wrap: wrap;
  gap: 1rem;
  .regenerate-button {
    color: #fff;
    background: #166bda;
  }
}
.share-on-social-media {
  border: 1px solid black;
  border-radius: 5px;
  font-size: 1rem;
  padding: 7px;
  .text {
    line-height: 100%;
    margin: 0;
  }
  .link {
    line-height: 100%;
    word-break: break-all;
  }
}
.social-media-content {
  margin: 5px 0px;
  .copy-button {
    width: 100%;
    background: var(--btnBlue);
    color: var(--white);
    text-transform: none;
  }
  .icon-container {
    display: flex;
    flex-wrap: wrap;
    .social-icons {
      display: flex;
      gap: 10px;
      margin: 0px 10px;
    }
  }
}
.cancel-button {
  background-color: var(--grey);
  color: var(--white);
}
@media (max-width: "900px") {
  .showUniqueID_Main {
    max-width: 90%;
    // min-height: 60vh;
  }
}
.modalContainer .modalMain {
  max-height: 90vh;
  overflow: auto;
}
