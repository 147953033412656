.FMModalBody1 {
  background-image: linear-gradient(#f7f7f7, #fff);
  height: 95vh;
  overflow: auto;
}
.FMBlockModalMain {
  max-width: 90% !important;
  height: 91vh;
  background-image: linear-gradient(#f7f7f7, #fff);
}
.themeModal,
.DiscussionModal > div:nth-child(2) {
  display: block;
  width: 50%;
  flex-basis: revert;
}

.discussion-button {
  margin: 0.5rem 0.8rem;
}
.rightBoxContent {
  height: 450px;
  .themes {
    position: relative;
    margin-bottom: 1rem;
    height: 40px;
    border-radius: 4px;
    cursor: pointer;
    .tickmark {
      justify-content: center;
      display: flex;
      height: 100%;
      align-items: center;
    }
  }
}
@media screen and (max-width: 426px) {
  .rightBoxContent {
    height: 100%;
  }
}
