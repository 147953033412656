.image-block .image-view {
  position: relative;
  img {
    max-width: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%;
    border-radius: 10px;
  }
}
