.datepicker {
  background-color: white;
  border: none;
  border-width: 0px;
  .MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.MuiFormLabel-colorInfo.Mui-required.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.Mui-focused {
    display: none;
  }
  .MuiOutlinedInput-notchedOutline {
    display: none;
  }
}
.datepicker.display_none {
  .MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.MuiFormLabel-colorInfo.Mui-required.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined {
    display: none;
  }
}
.save_cohort_button {
  background-color: var(--btnBlue);
  color: var(--white);
  text-align: center;
  margin-top: 1rem;
  float: right;
  width: 20%;
}
.header_text_black {
  color: black !important;
}
.datepicker.inputBoxContGreen:focus-within {
  border: 2px solid var(--inputGreen);
}
.datepicker.inputBoxContGreen {
  border: 2px solid transparent;
}
.fullwidth {
  width: 100%;
}
.cohort_form {
  padding: 10px;
}

.preview-card {
  border-radius: 4px;
  padding: 0px 10px;
}
.preview-profile-icon {
  background-color: #cac6c6;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  text-align: center;
}
.cohort_modal {
  max-height: 97vh;
}
@media (min-width: 1440px) {
  .preview-msg {
    width: 98%;
  }
  .close-icon {
    width: 2%;
  }
}

@media (max-width: 600px) {
  .autoApprove {
    margin-top: 5rem;
  }
}
