.MuiTableHead-root > .MuiTableRow-root.MuiTableRow-head {
  font-weight: bold !important;
}
.table_head {
  font-weight: 600;
  border-bottom: 1px solid grey;
  text-align: left;
  padding-left: 30px;
  padding-right: 30px;
}

.table_head_actions {
  font-weight: 600;
  border-bottom: 1px solid grey;
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
}

@media (max-width: 600px) {
  .MuiBox-root > .MuiPaper-root > .MuiToolbar-root > .tss-qbo1l6-MUIDataTableToolbar-actions {
    text-align: right;
  }
}
