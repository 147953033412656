.breathing-block{
    .circle_all {
        position:relative;
        height:100vh;
        margin-left:auto;
        margin-right:auto;
        // border: 4px solid #fff;
        .circle_A {
          position:absolute;
          left:50%;
          bottom:50%;
          transform: translate(-50%,50%);
          width:200px;
          height:200px;
          border-radius: 50%;
          background-color: #FF6B3D;
          z-index:999;
          
        }
        .circle_B {
          position:absolute;
          left:50%;
          bottom:50%;
          transform: translate(-50%,50%);
          width:250px;
          height:250px;
          border-radius: 50%;
          background-color: #EB3B00;
          opacity:.7;
          z-index:998;
          animation-name: fadeOut_B;
          animation-duration: 1.5s ;
          animation-timing-function: ease-in-out;
          animation-iteration-count: infinite;
          // animation-fill-mode: both;
      
          @keyframes fadeOut_B {
            0% {opacity:0;}
            20% {opacity:.7;}
            100% {opacity:0; width:300px; height:300px;}
          }
        }
        .circle_C {
          position:absolute;
          left:50%;
          bottom:50%;
          transform: translate(-50%,50%);
          width:300px;
          height:300px;
          border-radius: 50%;
          background-color: #EB3B00;
          opacity:.5;
          z-index:997;
          animation-name: fadeOut_C;
          animation-duration: 1.5s;
          animation-timing-function: ease-in-out;
          animation-iteration-count: infinite;
          // animation-fill-mode:both;
      
          @keyframes fadeOut_C {
            0% {opacity:0;}
            40% {opacity:.5;}
            100% {opacity:0; width:350px; height:350px;}
          }
        }
        .circle_D {
          position:absolute;
          left:50%;
          bottom:50%;
          transform: translate(-50%,50%);
          width:350px;
          height:350px;
          border-radius: 50%;
          background-color: #EB3B00;
          opacity:.3;
          z-index:996;
          animation-name: fadeOut_D;
          animation-duration: 1.5s;
          animation-timing-function: ease-in-out;
          animation-iteration-count: infinite;
          // animation-fill-mode:both;
      
          @keyframes fadeOut_D {
            0% {opacity:0;}
            60% {opacity:.3;}
            100% {opacity:0; width:400px; height:400px;}
          }
        }
        .circle_E {
          position:absolute;
          left:50%;
          bottom:50%;
          transform: translate(-50%,50%);
          width:400px;
          height:400px;
          border-radius: 50%;
          background-color: #EB3B00;
          opacity:.1;
          z-index:996;
          animation-name: fadeOut_E;
          animation-duration: 1.5s;
          animation-timing-function: ease-in-out;
          animation-iteration-count: infinite;
          // animation-fill-mode:both;
      
          @keyframes fadeOut_E {
            0% {opacity:0;}
            80% {opacity:.1;}
            100% {opacity:0; width:450px; height:450px;}
          }
        }
      }
}
