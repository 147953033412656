.pre-breadcrumb-container-main{
    .MuiBreadcrumbs-separator{
      color: var(--white);
      font-size: 1.2rem;
    }
  }
  .post-breadcrumb-container-main{
    .MuiBreadcrumbs-separator{
      color: black;
      font-size: 1.2rem;
    }
  }

  .breadcrumb {
    position: sticky;
    top: -1px;
    left: 0px;
    right: 0px;
    // height: 60px;
    // line-height: 60px;
    // overflow-y: hidden;
    z-index: 5;
    span:first-child {
        font-weight: 600;
    }
    @media (max-width:430px){
      span:first-child {
        max-width: 15rem;
    }
      
    }
    .course-header-title{
        font-size: 14px;
        cursor: pointer;
    }
    .course-title-disable{
        font-size: 14px;
    }
}

.greyBg{
  background-color: var(--primary-white);
}
.darkBg{
  background-color: var(--primary-black);
}