.modalContainer {
  backdrop-filter: blur(2px);
  overflow-y: auto;
  .modalMain {
    .modalRight {
      .loginImageCont {
        .hideLoginImage {
          height: 90%;
        }
      }
    }
  }
}
.modalMain {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 880px;
  max-height: 100vh;
  // overflow-y: ;
  .iconContainer2 {
    display: none;
  }
  .modalLeft {
    padding: 1rem;
    .loginInputHeader {
      font-size: 30px;
      // margin-bottom: 1rem;
    }
  }
  .modalRight {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    .loginImageCont {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      .loginImage {
        object-fit: cover;
        height: 100%;
        width: 100%;
        float: left;
        top: 2rem;
      }
      .iconContainer {
        position: absolute;
        right: 20px;
        top: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2rem;
        height: 2rem;
        background-repeat: no-repeat;
        background-size: 30px 30px;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 4px;
        cursor: pointer;
      }
    }
    .rightBtnMain {
      width: 100%;
      margin-top: 1rem;
      .rigthBtnText {
        font-size: 16px;
        padding-bottom: 10px;
        color: var(--primary-black);
      }
    }
  }
}
.loginLayoutMain {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .loginContainer {
    // background-color: rebeccapurple;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .forgotPasswordMain {
      // background-color: rebeccapurple;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      .forgotPasswordText {
        font-size: 14px;
      }
      // .forgotPasswordText:hover{
      //     color: var(--primary-yellow);
      // }
    }
  }
  .leftBtnMain {
    width: 100%;
    margin-top: 1rem;
    width: 100%;
    .leftBtn {
      width: 100%;
    }
  }
}
.signupMain {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .signupFlex {
    display: flex;
    gap: 1rem;
  }
  .toLoginText {
    font-size: 14px;
    text-align: left;
  }
  .leftBtnTxt {
    font-size: 14px;
    font-weight: bold;
    text-align: left;
  }
  .checkBoxSignup {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    .checkboxSignupCheck {
      cursor: pointer;
    }
    .checkboxLabelSignup {
      cursor: pointer;
      .checkboxTermsSignup {
        text-decoration: underline;
        font-weight: bold;
      }
    }
  }
  .signupBtn {
    width: 100%;
    .leftBtn {
      width: 100%;
    }
  }
}
.FMModalMain {
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 40vw;
  min-width: 30vw;
  min-height: 20vh;
  border-radius: 4px;
  padding: 1rem;
  overflow: auto;
  .FMClose {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-size: 30px 30px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 4px;
    cursor: pointer;
    z-index: 11;
  }
  .FMModalHeader {
    font-size: 30px;
    margin-bottom: 1rem;
    // color: var(--white);
  }
  .FMModalBody {
    width: 100%;
    overflow: auto;
  }
}

@media (max-width: 1200px) {
  .modalMain {
    max-width: 90%;
    overflow: auto;
    .modalLeft {
      display: flex;
      flex-direction: column;
      .iconMain2 {
        float: right;
        background-color: rebeccapurple;
        .iconContainer2 {
          position: absolute;
          top: 2.5rem;
          right: 1.5rem;
          // display: block;
          // float: right;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 2rem;
          height: 2rem;
          background-repeat: no-repeat;
          background-size: 30px 30px;
          background-color: rgba(255, 255, 255, 0.3);
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }
    .modalRight {
      padding: 10px 1.5rem;
      .loginImageCont {
        .iconContainer {
          display: none;
        }
      }
    }
  }
  .FMModalMain {
    max-width: 60vw;
  }
}
@media (max-width: 720px) {
  .FMModalMain {
    max-width: 90vw;
  }
  .signupMain {
    .signupFlex {
      flex-direction: column;
      gap: 0;
    }
  }
  .modalRight {
    .loginImage {
      display: none;
    }
  }
}
@media (max-width: "426px") {
  .FMModalMain {
    overflow: auto;
    max-height: 90vh;
  }
  .modalContainer {
    .modalMain {
      .modalRight {
        .loginImageCont {
          .hideLoginImage {
            display: none;
          }
        }
      }
    }
  }
}
