.search_container {
  position: relative;
  //   margin-bottom: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.search_list_item::-webkit-scrollbar,
.search_list_item::-webkit-scrollbar-thumb {
  width: 26px;
  border-radius: 13px;
  background-clip: padding-box;
  border: 10px solid transparent;
}

.search_list_item::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
}
