.previewModal {
  background-color: rebeccapurple;
  max-width: 90vw;
  height: 95vh;
}
.container {
  position: relative;
  text-align: left;
  line-height: 1.5;
  height: 862px;
  width: 1200px;
  aspect-ratio: 1519/1092;
  .childContainer {
    position: absolute;
    top: 13%;
    left: 8%;
    text-align: left;
  }
  .headerContainer {
    display: flex;
    align-items: start;
    .box1,
    .box2 {
      max-width: 400px;
    }
  }
  .certificateImage {
    height: auto;
    width: 20%;
    text-align: center;
    margin-right: 40px;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
  .institutionName {
    font-family: "myriad-pro4";
    font-size: 3rem;
    font-style: "MyriadPro-Regular";
    color: #393838;
    text-transform: uppercase;
  }
  .institutionDesc {
    font-family: "myriad-pro4";
    margin-top: 0.8rem;
    font-size: 1rem;
    font-style: "MyriadPro-Regular";
    color: #393838;
    text-transform: uppercase;
    line-height: 1.5;
  }
  .cert_text_1 {
    font-family: "myriad-pro4";
    font-size: 1rem;
    font-style: "MyriadPro-Regular";
    color: #949799;
    text-transform: uppercase;
    position: absolute;
    top: 130%;
  }
  .cert_text_2 {
    font-family: "myriad-pro4";
    font-size: 1rem;
    font-style: "MyriadPro-Regular";
    color: #949799;
    text-transform: uppercase;
    position: absolute;
    top: 225%;
  }
  .learnerName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: myriad-pro2;
    font-size: 2rem;
    font-style: "MyriadPro-Regular";
    color: #c94a4d;
    position: absolute;
    top: 170%;
  }
  .courseName {
    font-size: 1.2rem;
    font-style: "MyriadPro-Regular";
    color: #005eb4;
    hyphens: auto;
    word-wrap: break-word;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    position: absolute;
    top: 265%;
    font-weight: bold;
    .certificateSuffix {
      margin-left: 5px;
    }
  }
  .logoContainer {
    align-items: center;
    top: 65%;
    left: 4%;
    display: flex;
    position: absolute;
    width: 95%;
  }

  .msg1,
  .msg2,
  .msg3 {
    line-height: 1.5;
    white-space: nowrap;
  }
  .footerContainer {
    display: flex;
    align-items: center;
    gap: 11rem;
    font-size: 0.5rem;
    margin: 0rem 2rem;
    position: absolute;
    bottom: 0.01rem;
    margin: 0rem 3rem;
    .footer {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }
  }
  .signatoryName {
    line-height: 1.5;
    font-family: "myriad-pro4";
    font-size: 1rem;
    font-style: MyriadPro-Regular;
    color: #0b5db8;
    font-weight: bold;
  }
  .signatoryDesignation {
    line-height: 1.5;
    font-family: myriad-pro0;
    font-size: 0.8rem;
    font-style: MyriadPro-Regular;
    color: #0b5db8;
  }
}
