.margin-top-2 {
  margin-top: 1px;
}
.bg-white {
  background-color: white;
}
#nested-list-subheader {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.facilitator_badge {
  background-color: #ffe9b9;
  color: var(--primary-black);
}

.moderator_badge {
  background-color: #d1fae5;
  color: var(--primary-black);
}
