.mcq-block {
  padding: 1rem;
  position: relative;
  border-radius: 10px;
  .title_container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }
  .userInputButton:hover {
    box-shadow: 0 0 5px grey;
    background-color: #f5c458;
  }
  .block-title {
    font-size: 20px;
    .required {
      font-weight: bold;
      font-size: 20px;
      color: red;
      padding: 0.5rem;
    }
  }
  .block-options {
    position: relative;
    margin-top: 10px;
  }
  .block-option {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 200px;
    cursor: pointer;
    margin: 0px 20px 20px 0px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
  .block-option:hover {
    // box-shadow: 0 0 5px grey;
    background-color: grey;
  }
  .selected {
    background-color: black;
    color: #fff;
  }
  .block-option .block-details {
    display: block;
    padding: 15px;
    .block-text {
      font-size: 20px;
      display: flex;
      justify-content: center;
    }
    .block-image {
      display: block;
      width: 200px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
}
.disableBlock {
  opacity: 0.8;
}
.highlightBlock {
  border: 2px solid tomato;
  box-shadow: 3px 3px 12px #fa9393b3;
}
