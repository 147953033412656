.blockActions{
    position: absolute;
    right: 0;
    margin: 10px;
    z-index: 2;
    border-radius: 0.5rem;
}
.actionIcons:hover{
    background-color:var(--postlogin-primary-color);
    color: var(--white);
}
.hideActionIcons{
    display: none;
}
