.resetPassword {
  width: 100%;
  min-height: 80vh;
  // background-color: red;
  margin: 0px auto;
  display: flex;
  // flex-direction: column;
  justify-content: center;
  align-items: center;
  .resetContainer {
    background-color: #f2f4f8;
    border-radius: 4px;
    width: 50%;
    padding: 1rem;
    // height: 100vh;
    .resetText {
      font-size: 14px;
      padding: 10px 0 10px 0;
    }
    .inputBox {
      margin: 0.5rem 0rem;
    }
    .resetButton {
      width: 100%;
      padding-top: 1rem;
      padding-bottom: 1rem;
      margin: 0.5rem 0rem;
    }
  }
}
@media (max-width: 900px) {
  .resetPassword {
    .resetContainer {
      min-width: 80%;
    }
  }
}
