.pre-back-to-top-icon {
  background-color: var(--primary-yellow);
  color: var(--primary-black);
  width: 50px;
  height: 50px;
  & :hover {
    color: var(--white);
  }
}
.post-back-to-top-icon {
  background-color: var(--postlogin-primary-color);
  color: var(--white);
  width: 50px;
  height: 50px;
}
#back-to-top-anchor div {
  z-index: 200;
  right: 1rem;
    bottom: 1rem;
}