.list-view {
  position: relative;
  .list-block {
    padding: 1rem;
    .block-title {
      font-weight: 600;
      font-size: 20px;
    }
    .block-text {
      position: relative;
      margin: 10px 0px 20px;
    }
  }
}
.list-items {
  position: relative;
  margin-top: 20px;
  margin-left: 10%;
}
.one-list-item {
  position: relative;
  padding: 0.5rem;
}
.one-list-content .list-text {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.2);
  width: calc(100% - 90px);
  border-radius: 50px;
  border-bottom-left-radius: 4px;
  padding: 14px 28px;
  font-weight: 100;
}
.one-list-content {
  .art-image-left {
    height: 80px;
    width: 80px;
    float: left;
  }
  .art-image-right {
    height: 80px;
    width: 80px;
    float: right;
  }
}

.one-list-item .one-list-content.is-right {
  float: right;
}
.one-list-item .one-list-content.is-right:last-child {
  float: inherit;
  width: 70%;
  margin-left: 30%;
}
.one-list-item .one-list-content.is-right .list-text {
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 4px;
}
.one-list-item .one-list-content {
  position: relative;
  width: 70%;
}
.one-list-item .one-list-content.is-right .list-image {
  margin-right: 0px;
  margin-left: 10px;
  float: right;
}
.one-list-content .entity-title {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.2);
  width: calc(100% - 90px);
  border-radius: 50px;
  border-bottom-left-radius: 4px;
  padding: 14px 28px;
  margin: 16px 0px !important;
  font-size: 20px;
  border: 2px solid transparent;
}
.one-list-content.is-right .entity-title {
  border-radius: 50px 50px 4px !important;
}
