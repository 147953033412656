/* Style for the parent container */
.parent {
  // display: flex; /* Use flexbox to control the layout */
  position: relative;
  overflow-x: hidden;
  height: 90vh;
  .left {
    width: 100%; /* Take up 30% of the parent's width */
    // height: 82vh;
    overflow: auto;
    border-radius: 2px;
    background-color: #365082;
    .createTopicBox {
      border: 1px solid blue;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px;
      border-radius: 3px;
    }
    .topicSearch {
      margin: 0 15px 15px 15px;
      align-items: center;
      // padding: 0 10px;
      border-radius: 30px;
      padding: 0 20px;
      background-color: white;
      display: flex;
      input {
        height: 40px;
        outline: none;
        width: 100%;
        border: none;
      }
    }
    .sidebar__list {
      list-style: none;
      padding: 0;
      margin-top: 10px;
      overflow: auto;
      .sidebar__item {
        display: flex;
        align-items: center;
        padding: 10px;
        cursor: pointer;
        transition: background-color 0.3s;
        border-left: 5px solid transparent;
        .active-bar {
          /* Style for the vertical line */
          width: 3px; /* Adjust the width of the vertical line as needed */
          background-color: #007bff; /* Match the active item's background color */
          height: fit-content;
        }
      }

      .sidebar__item:hover {
        background-color: #f0f0f0;
        border-radius: 4px;
      }

      .sidebar__item-text {
        margin-left: 10px;
        font-size: 16px;
        color: #333;
      }

      /* Active topic style */
      .sidebar__item.active {
        // background-color: #e4e6eb;
        border-radius: 0px 4px 4px 0px;
        border-left: 5px solid black;
      }
    }
  }

  .visible {
    // display: none;
    -webkit-animation: slide-left 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-left 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  .hidden {
    // display: none;
    -webkit-animation: slide-right 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-right 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  /* Style for the right div */
  .right {
    // flex: 70%; /* Take up 70% of the parent's width */
    width: 100%;
    z-index: 99;
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    // height: 82vh;
    overflow: hidden;
    .no-selected-topic {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 400px;
    }
    .topicHeaderContainer {
      // background-color: rgba(25, 118, 210, 0.08);
      width: 100%;
      position: sticky;
      top: 0;
      padding: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      .topicHeaderLeft {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        .topic__title {
          // margin-left: 20px;
          font-weight: 600;
        }
        .topic__description {
          position: absolute;
          top: 56px;
          left: 0;
          right: 0;
          margin: 0;
          z-index: 99;
          padding: 20px;
          box-shadow:
            rgba(17, 17, 26, 0.05) 0px 4px 16px,
            rgba(17, 17, 26, 0.05) 0px 8px 32px;
          // width: 600px;
          background-color: white;
          font-size: 14px;
        }
      }
    }
    .commentBox {
      height: 80%;
      // overflow: auto;
    }
    .sent-messages {
      padding: 0 10px;
    }
  }
}
.topicAction {
  padding: 0.5rem;
  cursor: pointer;
}
.edit__button {
  background-color: var(--btnBlue);
  color: var(--white);
  float: right;
}

@media screen and (min-width: 768px) {
  .parent {
    display: flex; /* Use flexbox to control the layout */
    height: 100%;
    overflow: hidden;
    .left {
      width: 35%; /* Take up 30% of the parent's width */
      height: 82vh;
      overflow: auto;
      .createTopicBox {
        border: 1px solid blue;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px;
        border-radius: 3px;
      }
      .topicSearch {
        margin: 0 15px 15px 15px;
        align-items: center;
        // border-radius: 30px;
        padding: 0 20px;
        background-color: white;
        display: flex;
        input {
          height: 40px;
          outline: none;
          width: 100%;
          border: none;
        }
      }
      .sidebar__list {
        list-style: none;
        padding: 0;
        margin-top: 10px;
        overflow: auto;
        .sidebar__item {
          display: flex;
          align-items: center;
          padding: 10px;
          cursor: pointer;
          transition: background-color 0.3s;
          border-left: 5px solid transparent;
          .active-bar {
            /* Style for the vertical line */
            width: 3px; /* Adjust the width of the vertical line as needed */
            background-color: #007bff; /* Match the active item's background color */
            height: fit-content;
          }
        }

        .sidebar__item:hover {
          background-color: #f0f0f0;
          border-radius: 4px;
        }

        .sidebar__item-text {
          margin-left: 10px;
          font-size: 16px;
          color: #333;
        }

        /* Active topic style */
        .sidebar__item.active {
          // background-color: #e4e6eb;
          border-radius: 0px 4px 4px 0px;
          border-left: 5px solid black;
        }
      }
    }

    /* Style for the right div */
    .right {
      // flex: 70%; /* Take up 70% of the parent's width */
      width: 65%;
      position: relative;
      // height: 82vh;
      overflow: hidden;
      .topicHeaderContainer {
        // background-color: rgba(25, 118, 210, 0.08);
        // box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
        width: 100%;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        .topicHeaderLeft {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          .topic__title {
            // margin-left: 20px;
            font-weight: 600;
          }
          .topic__description {
            position: absolute;
            top: 56px;
            left: 0;
            right: 0;
            margin: 0;
            z-index: 99;
            padding: 20px;
            box-shadow:
              rgba(17, 17, 26, 0.05) 0px 4px 16px,
              rgba(17, 17, 26, 0.05) 0px 8px 32px;
            // width: 600px;
            background-color: white;
            font-size: 14px;
          }
        }
      }
      .commentBox {
        height: 80%;
        // overflow: auto;
      }
    }
  }
}
@media screen and (min-width: 900px) {
  .parent {
    display: flex; /* Use flexbox to control the layout */
    height: 100%;
    overflow: hidden;
    .left {
      width: 20%; /* Take up 30% of the parent's width */
      height: 90vh;
      overflow: auto;
      border-radius: 2px;
      background-color: #365082;
      .createTopicBox {
        border: 1px solid blue;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px;
        border-radius: 3px;
      }
      .topicSearch {
        margin: 0 15px 15px 15px;
        align-items: center;
        // padding: 0 10px;
        border-radius: 30px;
        padding: 0 20px;
        background-color: white;
        display: flex;
        input {
          height: 40px;
          outline: none;
          width: 100%;
          border: none;
        }
      }
      .sidebar__list {
        list-style: none;
        padding: 0;
        margin-top: 10px;
        overflow: auto;
        .sidebar__item {
          display: flex;
          align-items: center;
          padding: 10px;
          cursor: pointer;
          transition: background-color 0.3s;
          border-left: 5px solid transparent;
          .active-bar {
            /* Style for the vertical line */
            width: 3px; /* Adjust the width of the vertical line as needed */
            background-color: #007bff; /* Match the active item's background color */
            height: fit-content;
          }
        }

        .sidebar__item:hover {
          background-color: #f0f0f0;
          border-radius: 4px;
        }

        .sidebar__item-text {
          margin-left: 10px;
          font-size: 16px;
          color: #333;
        }

        /* Active topic style */
        .sidebar__item.active {
          // background-color: #e4e6eb;
          border-radius: 0px 4px 4px 0px;
          border-left: 5px solid black;
        }
      }
    }

    /* Style for the right div */
    .right {
      // flex: 70%; /* Take up 70% of the parent's width */
      width: 80%;
      position: relative;
      // height: 82vh;
      overflow: hidden;
      .topicHeaderContainer {
        // background-color: rgba(25, 118, 210, 0.08);
        // box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
        width: 100%;
        padding: 7.7px;
        display: flex;
        // margin: 0 10px;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid black;
        position: relative;
        .topicHeaderLeft {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          .topic__title {
            // margin-left: 20px;
            font-weight: 600;
          }
          .topic__description {
            position: absolute;
            top: 56px;
            left: 0;
            right: 0;
            margin: 0;
            z-index: 99;
            padding: 20px;
            box-shadow:
              rgba(17, 17, 26, 0.05) 0px 4px 16px,
              rgba(17, 17, 26, 0.05) 0px 8px 32px;
            // width: 600px;
            background-color: white;
            font-size: 14px;
          }
        }
      }
      .commentBox {
        height: 80%;
      }
      .sent-messages {
        margin-bottom: 0;
        position: relative;
      }
    }
  }
}

@-webkit-keyframes slide-left {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes slide-left {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}
@keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.not_topics_selected_img {
  height: 400px;
}
