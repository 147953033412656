.enroll_completed_courses {
  // style={{
  //   background: "#fff",
  //   boxShadow: "0 4px 2px -2px rgba(0, 0, 0, 0.2)",
  //   paddingBottom: "1rem",
  //   paddingTop: "1px",
  // }}
  // background: #fff;
  padding-bottom: 1rem;
  padding-top: 1px;
  // box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
}
@media only screen and (max-width: 426px) {
  .enrolled-container {
    display: grid;
    overflow: hidden;
    gap: 1rem;
    grid-template-columns: 100%;
  }
  .userCard {
    display: flex;
    justify-content: start;
  }
}
@media only screen and (min-width: 426px) and (max-width: 600px) {
  .enrolled-container {
    // display: grid;
    gap: 1.5rem;
    flex-wrap: wrap;
    grid-template-columns: auto auto;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 820px) {
  .enrolled-container {
    display: grid;
    flex-wrap: wrap;
    gap: 1rem;
    grid-template-columns: 49.5% 49.5%;
    // box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
  }
}
@media only screen and (min-width: 821px) {
  .enrolled-container {
    display: grid;
    flex-wrap: wrap;
    gap: 1rem;
    grid-template-columns: 32.5% 32.5% 32.5%;
    // box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
  }
}
