.insights_modal{
    min-height: 50vh;
    .insightMain{
        padding: 0 1rem 0 1rem;
        .insightData{
            margin-top: 2rem;
            display: flex;
            justify-content: space-between;
        }
        .csvButtonContainer{
            margin-top: 1rem;
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }
}