.managedMain {
  margin-top: 1rem;
}

@media screen and (min-width: 1024px) {
  .flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
