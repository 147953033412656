.termsModalMain {
  // max-width: 60vw;
  padding: 1rem 2rem 1rem 2rem;
}
.termsText {
  font-size: 14px;
  // background-color: red;
}
.termsTextMargin {
  margin-bottom: 10px;
}
.termsTextBold {
  font-weight: bold;
}
.terms-contact-us {
  color: var(--primary-yellow);
  cursor: pointer;
}
