.divider-text2::before {
  content: "";
  height: 0.2em;
  width: 100%;
  position: absolute;
  background-color: #e9e9ef;
  right: 0;
  bottom: 50%;
  z-index: 0;
}
.divider-content-text {
  display: flex;
  align-items: center;
  gap: 1rem;
}
@media (max-width: 425px) {
  .divider-content-text {
    display: block;
  }
  .divider-text2::before {
    content: "";
    height: 0.4rem;
    width: 90%;
    position: absolute;
    background-color: #e9e9ef;
    right: 38px;
    top: 150%;
    z-index: 0;
  }
}
.divider-block {
  position: relative;
  .divider-text2 {
    margin: 2rem 0rem;
    position: relative;
    font-weight: 600;
    font-size: 22px;
    display: flex;
    width: 100%;
  }
  .divider-text2 > span {
    position: relative;
    padding-right: 20px;
  }
  .divider-text2 > span.light {
    background-color: white;
    color: #1a1a1a;
  }
  .divider-text2 > span.dark {
    background: #f7f7f7;
    color: #1a1a1a;
  }
  hr {
    color: #e9e9ef;
  }
  .divider-btn {
    color: white;
    background-color: blue;
    width: fit-content;
    padding: 2px 7px;
    border-radius: 10px;
    font-size: 14px;
  }
}
.animation-modal {
  height: 100%;
  min-width: 100%;
}
