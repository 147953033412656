.editBlock {
  height: 65vh;
  overflow: scroll;
}
.blue {
  color: white;
  background-color: blue;
}

.mcqStyle {
  margin-top: 2;

  @media (max-width: 600px) {
    margin-top: 8rem; /* Adjusted height for mobile view */
  }
}
