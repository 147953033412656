.match-view {
  width: 100%;
  position: relative;
  display: inline-block;
  vertical-align: top;
  .container-block {
    padding: 20px;
    border-radius: 10px;
  }
  .block-title {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 10px;
  }
  .block-text {
    font-size: 20px;
    position: relative;
    margin: 10px 0px 20px;
  }
  .match-options {
    position: relative;
    display: table;
    width: 100%;
    font-size: 20px;
  }
  .block-options {
    margin-top: 20px;
    position: relative;
  }
  .block-options.block-options-left {
    border-right: 40px solid transparent;
    position: relative;
    width: 50%;
    display: table-cell;
    vertical-align: top;
  }
  .block-options.block-options-right {
    border-left: 40px solid transparent;
    position: relative;
    width: 50%;
    display: table-cell;
    vertical-align: top;
  }
  .bg-selected {
    background: black;
    color: #fff;
    border-radius: 10px;
  }
  .bg-default {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
  @media (max-width: 770px) {
    .block-options.block-options-right {
      width: 100%;
      border-left: 0;
      display: block;
    }
    .match-options {
      display: block;
    }
  }
  .match-options .block-options > div {
    width: auto;
  }
  .match-options .block-option {
    display: block;
  }
  .block-options > div {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 200px;
    cursor: pointer;
    margin: 0px 20px 20px 0px;
    border-radius: 10px;
  }
  .block-option .block-image {
    display: block;
    width: 200px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .block-image {
    position: relative;
    display: table-cell;
    vertical-align: top;
    width: 400px;
    border-radius: 10px;
    background-color: #1a1a1a;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .block-option .block-details {
    display: block;
    padding: 20px;
  }
  .block-details {
    position: relative;
    display: table-cell;
    vertical-align: top;
  }
  .block-option .block-text {
    margin: 0px;
  }
  .block-text {
    position: relative;
    margin: 10px 0px 20px;
  }
  .block-table {
    position: relative;
    display: table;
    width: 100%;
  }
}
.match-block .match-colors {
  position: absolute;
  left: 8px;
  top: -23px;
  display: block;
  z-index: 99;
  .one-color {
    display: inline-block;
    vertical-align: top;
    height: 7px;
    width: 20px;
    margin-right: 3px;
    cursor: inherit;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
}

.required {
  color: red;
}
