.supportText{
    word-wrap:break-word;
    .linkText{
        color: #0F82AF;
        text-decoration: underline;
    }
}
.supportImage {
    width: 300px;
    height: 400px;
    background-size:auto;
    margin: 5rem 5rem 0 0;
}
@media (max-width:600px){
    .supportImage{
        margin: 0;
    }
}
.whatsAppContainer{
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.whatsappNumber{
    cursor: pointer;
    color: rgb(15, 130, 175);
    font-weight: bold;
}
.whatsappIcon{
    border-radius: 10px;
    font-size: 2rem;
    color: rgb(15, 130, 175);
}