.containerBlock {
  .uploadFile {
    padding: 100px 40px;
  }
}

.imagesizes {
  width: 100%;
}

.imageUploaderWrapper {
  max-width: 300px; /* Set maximum width */
  margin: 0 auto; /* Center align the component */
}

@media (min-width: 600px) {
  .imagesizes {
    width: 50%;
  }
  .imageUploaderWrapper {
    max-width: 250px; /* Adjust the maximum width as needed */
  }
}
