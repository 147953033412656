.completed_badge {
  background: #d1fae5;
  padding: 0 15px;
  border-radius: 50px;
  border: 1px solid #10b981;
  display: inline-block;
  color: #047857;
  font-size: 12px;
}

.cancelled_badge {
  background: #fecdd3;
  padding: 0 15px;
  border-radius: 50px;
  border: 1px solid #fb7185;
  display: inline-block;
  color: #f43f5e;
  font-size: 12px;
}

.created_badge {
  background: #fef9c3;
  padding: 0 15px;
  border-radius: 50px;
  border: 1px solid #eab308;
  display: inline-block;
  color: #a16207;
  font-size: 12px;
}

.payment_table_head {
  font-weight: 600;
  border-bottom: 1px solid grey;
  text-align: center;
  // padding-left: 10px;
  padding-right: 30px;
  cursor: pointer;
}
