:root {
  --white: #ffffff;
  --grey: #a6a0a0;
  --primary-white: #f7f7f7;
  --primary-black: #1a1a1a;
  --primary-yellow: #ffd370;
  --primary-background-color: #232323;
  --primary-link-color: #409fff;
  --bright-blue: #409fff;
  --error-red: #f7544c;
  --text-red: #df3030e6;
  --border-red: #df30309c;
  --modalBlack: #232323;
  --greyBackground: rgba(0, 0, 0, 0.1);
  --btnBlue: #166bda;
  --inputGreen: #31eda8;
  --inputRed: #f7544c;
  --postlogin-primary-color: #166bda;
  --cardShadow: 0px 0px 20px #e9e9ef;
  --button-tangerine: #ea8533;
}
.defaultStyle {
  background-color: #232323;
  color: #fff;
  border-radius: 10px;
}
.course-main-block {
  margin-top: 3rem;
}
.theme-a {
  background-image: linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%);
  color: #232323;
}
.theme-b {
  background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
  color: #232323;
}
.theme-c {
  background-image: linear-gradient(to top, #fdcbf1 0%, #fdcbf1 1%, #e6dee9 100%);
  color: #232323;
}
.theme-d {
  background-image: linear-gradient(to top, #accbee 0%, #e7f0fd 100%);
  color: #232323;
}
.theme-e {
  background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
  color: #232323;
}
.theme-f {
  background-image: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);
  color: #232323;
}
.theme-g {
  background-image: linear-gradient(to top, #a8edea 0%, #fed6e3 100%);
  color: #232323;
}
.theme-h {
  background-image: linear-gradient(to top, #fff1eb 0%, #ace0f9 100%);
  color: #232323;
}
