.pageHeaderName {
  padding-bottom: 5px;
  font-weight: 700;
  font-size: 1.5rem;
}
.pageHeaderText {
  font-size: 18px;
  max-width: 600px;
  line-height: 30px;
}
