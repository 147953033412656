.inputBoxMain {
  .inputBoxCont {
    background-color: rgb(255, 255, 255);
    width: 100%;
    font-size: 18px;
    padding: 1rem;
    color: var(--primary-black);
    border: 2px solid transparent;
    outline: none;
    border-radius: 4px;
    display: flex;
    align-items: center;
    height: 50px;
    position: relative;

    .side_label {
      position: absolute;
      top: 50%;
      right: 0%;
      transform: translate(0%, -50%);
      padding: 10px;
      background-color: azure;
      border-left: 1px solid rgb(223, 238, 238);
      font-size: 14px;
      color: rgba(170, 170, 170, 1);
    }

    .inputTextBox {
      background-color: transparent;
      border: none;
      width: 100%;
      font-size: 16px;
    }
    .inputTextBox:focus {
      border: none;
      border-color: transparent;
      outline: none;
    }
    .passwordBtn {
      font-size: 12px;
      color: var(--white);
      border-radius: 4px;
      border: none;
      border-radius: 4px;
      padding: 3px;
      cursor: pointer;
    }
    .showPasswordBtn {
      background-color: var(--primary-black);
    }
    .hidePasswordBtn {
      background-color: var(--bright-blue);
    }
  }
  .inputTextBox:focus {
    border: none;
    border-color: transparent;
    outline: none;
  }
  .passwordBtn {
    font-size: 12px;
    color: var(--white);
    border-radius: 4px;
    border: none;
    border-radius: 4px;
    padding: 3px;
    cursor: pointer;
  }
  .showPasswordBtn {
    background-color: var(--primary-black);
  }
  .hidePasswordBtn {
    background-color: var(--bright-blue);
  }
}
.inputBoxContGreen:focus-within {
  border: 2px solid var(--inputGreen);
}
.inputBoxContRed {
  border: 2px solid var(--inputRed);
}
.errorMsg {
  color: #ff5050;
  font-size: 14px;
  margin: 5px;
  line-height: 1.43;
}
.checkBoxInputMain {
  .checkboxLabel {
    cursor: pointer;
    color: var(--white);
    font-size: 14px;
  }
}
.dropdownLabel {
  color: grey;
}
.inputFile {
  img-button {
    width: 100%;
    height: 200px; // Adjust height proportionally to width

    @media (min-width: 768px) {
      width: 25%; // For tablet and larger screens
    }

    @media (min-width: 1024px) {
      width: 25%; // For laptop and larger screens
    }
  }

  .imageBlock-imageUpload {
    width: 100%;
    height: 100%;
    border: 2px dotted var(--primary-black);
  }

  .courseCover-imageUpload {
    width: 100%;
    height: 300px;
    border: 2px dotted var(--primary-black);
  }
  .custom-img-upload {
    border: 2px dotted var(--primary-black);
    display: inline-flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
    padding: 50px 40px;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    border-radius: 4px;
    background-color: #f0f0f0;
    margin: 1rem 0rem;
    width: 250px;
    height: 200px;
  }

  .custom-file-upload {
    border: 2px dotted var(--primary-black);
    display: inline-flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
    padding: 50px 40px;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    border-radius: 4px;
    background-color: #f0f0f0;
    margin: 1rem 0rem;
  }
  .file-upload {
    display: none;
  }
}
