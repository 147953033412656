#toggle-block{
    padding: 1rem;
    border-radius: 10px;
    position: relative;
    .isRemove{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
.item-text {
    margin: 0rem 2rem;
    a{
        text-decoration: none;
        color: var(--primary-link-color);
    }
}
.toggle-button{
    border-radius: 50%;
    min-height: 25px;
    min-width: 25px;
    background-color: black;
    border: none;
    margin-right: 10px;
}
.item-title{
    display: flex;
    align-items: center;
}
@media (min-width:0px) and (max-width:500px){
.item-title > h4{
    width: 90%;
}}
.Active{
    background-color: var(--primary-yellow);
}
.inActive{
    background-color: var(--primary-black);
}
.grey{
    background-color: rgba(0,0,0,0.1);
}
.blue{
    background-color: var(--postlogin-primary-color);
}